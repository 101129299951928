module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SARAH WEISMAN","short_name":"SARAH WEISMAN - Artist and Sculptor from Berlin, Paris","description":"Prof. Sarah Weisman, born Esser 1977 in Münster, lives as an artist and sculptor in Berlin and teaches at the Trier University of Applied Sciences. As a „Meisterschülerin“ of Prof. Berndt Wilde (Kunsthochschule Berlin) she received several prizes and scholarships in Europe. Her oeuvre comprises more than 100 sculptures, drawings and watercolors.","start_url":"/","background_color":"#FFF","theme_color":"#3498DB","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cfe0a7d5114ac6b61beefce3d8e36f6f"},
    }]
